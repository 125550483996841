import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, message, Card } from 'antd';
import Config from '../../Config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';



function RegisterCompany(props) {



    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const [loading, setLoading] = useState(false);

    let navigate = useNavigate();

    const handleSubmit = (formData) => {
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
            ...formData
        }

        console.log(data);
        var api_config = {
            method: 'post',
            url: Config.base_url + 'Company/RegisterCompany',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log("response", response.data)

                if (response.data.status_code == 1) {
                    message.success(response.data.status_message);

                    navigate("/change-company");
                    setLoading(false);
                } else if (response.data.status_code == 0) {

                    message.error(response.data.status_message);
                    setLoading(false);
                }

            })
            .catch(function (error) {
                console.log("response", error)
            });
    }







    return (
        <>
            <div className="right-side-contents">
                <div className="page-content">
                <div className="page-header">
                        <h3 className="page-title">
                           Register a Company
                        </h3>

                    </div>

                    <Row justify="center">
                        <Col xs={24} md={12}>

                            <Card>
                                <Form layout='vertical' size='large' className='form-default' onFinish={handleSubmit}>
                                    <Row gutter={[24,0]}>
                                        <Col xs={24}>
                                            <Form.Item
                                                label="Company Name"
                                                name="Name"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label="Mobile Number"
                                                name="Mobile"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label="Phone Number"
                                                name="Phone"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label="NTN"
                                                name="NTN"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item
                                                label="Website"
                                                name="Website"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24}>
                                            <Form.Item
                                                label="Address"
                                                name="Address"
                                                rules={[
                                                    {
                                                        required: true,
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                    <div style={{ textAlign: "right", marginTop: "20px" }}>
                                        <Button type='primary' htmlType='submit' loading={loading}>Submit</Button>
                                    </div>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>

        </>
    );
}

export default RegisterCompany;