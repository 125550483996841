import React, { useEffect, useState } from 'react';

import Logo from "../../assets/images/logo/white-v.svg";
import { NavLink } from 'react-router-dom';
import { Link, useNavigate } from "react-router-dom";

import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { Button, Skeleton, Spin } from 'antd';
import Config from '../../Config';
import axios from 'axios';

function SelectCompany(props) {


    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const [ListOfCompanies, setListOfCompanies] = useState([]);
    const [loading, setLoading] = useState(false);


    const navigate = useNavigate();

    const handleSignOut = () => {
        localStorage.clear();
        navigate('/login');
    }


    useEffect(() => {
        setLoading(true);
        const data = {
            AccessKey: AccessKey,
            UserID: UserID,
        }

        //console.log(data);

        var api_config = {
            method: 'post',
            url: Config.base_url + 'Company/GetMemberCompanies',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log("response", response.data)
                if (response.data.status_code == 1) {
                    const list = response.data.listofCompanies;

                    console.log(list.length);
                    if (list.length > 1) {
                        setListOfCompanies(response.data.listofCompanies);
                        setLoading(false);
                    }
                    else if (list.length == 0) {
                        navigate("/company-register");
                    }
                    else {
                        const first = list[0];
                        localStorage.setItem("CompanyCode", first.CompanyCode);
                        localStorage.setItem("CompanyID", first.ID);
                        localStorage.setItem("CompanyName", first.Name);
                        localStorage.setItem("CompanyCode", first.CompanyCode);
                        navigate("/dashboard");
                    }


                }
                else {
                    setLoading(false);
                }


            })
            .catch(function (error) {
                console.log("response", error)
            });


    }, []);


    const handleCompany = (item) => {
        localStorage.setItem("CompanyCode", item.CompanyCode);
        localStorage.setItem("CompanyID", item.ID);
        localStorage.setItem("CompanyName", item.Name);
        localStorage.setItem("CompanyCode", item.CompanyCode);
        navigate("/dashboard");
    }

    return (
        <div className='auth-wrap'>
            <div className='left-col'>
                <div>
                    <img src={Logo} />
                    <h3>Streamline Your Finances: Welcome to Effortless Invoicing & Accounting!</h3>
                </div>
            </div>
            <div className='right-col'>
                <div className='header'>

                    <Button onClick={handleSignOut}>Sign Out</Button>

                </div>
                <div className='auth-form-wrap'>
                    <div className='section-title' style={{ marginBottom: "50px" }}>
                        <h2>Welcome to Hisaaber</h2>
                        <p>Get Ready to Experience Effortless Invoicing and Streamlined Accounting</p>
                    </div>


                    {
                        loading ?
                            <Skeleton active />
                            :
                            <ul className='list-actions'>
                                {
                                    ListOfCompanies.map((item, index) => (
                                        <li key={index}>
                                            <a href='#' onClick={(e) => handleCompany(item)}>
                                                <span>{item.Name}</span>
                                                <ChevronRightIcon />
                                            </a>
                                        </li>
                                    ))
                                }
                            </ul>
                    }




                </div>
            </div>
        </div>
    );
}

export default SelectCompany;