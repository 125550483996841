import React, { useEffect, useState } from "react";

import Logo from "../../assets/images/logo/white-v.svg";
import { Link, useNavigate } from "react-router-dom";
import { NavLink } from 'react-router-dom';
import { Form, Button, Input, message } from 'antd';
import Config from '../../Config';
import axios from "axios";

function Signup(props) {
    const [loading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState("");
    const navigate = useNavigate();


    const handleSubmit = (FormData) => {
        setLoading(true);
        const data = {
            ...FormData
        }

        console.log(data);
        var api_config = {
            method: 'post',
            url: Config.base_url + 'Users/SignUp',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };


        axios(api_config)
            .then(function (response) {
                console.log("response", response.data)

                if (response.data.status_code == 1) {
                    messageApi.success(response.data.status_message);
                    navigate("/login");
                    setLoading(false);
                } else if (response.data.status_code == 0) {
                    messageApi.error(response.data.status_message);
                }


            })
            .catch(function (error) {
                console.log("response", error)
            });
    }








    return (
        <>

            <div className='auth-wrap'>
                <div className='left-col'>
                    <div>
                        <img src={Logo} />
                        <h3>Streamline Your Finances: Welcome to Effortless Invoicing & Accounting!</h3>
                    </div>
                </div>
                <div className='right-col'>
                    <div className='header'>
                        <p>Already have an account?</p>
                        <NavLink to="/login">
                            <Button>Login</Button>
                        </NavLink>
                    </div>
                    <div className='auth-form-wrap'>


                        <div className='section-title'>
                            <h2>Sign Up</h2>
                            <p>Empower Your Business with Hassle-Free Financial Control. Join Us Today!</p>
                        </div>

                        <Form
                            layout='vertical'
                            size='large'
                            className='form-default'
                            onFinish={handleSubmit}
                        >
                            <Form.Item label="Full Name" name="FullName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Full Name!',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item label="Mobile Number" name='User_Mobile'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Mobile Number!',
                                    },
                                ]}>
                                <Input />
                            </Form.Item>
                            <Form.Item label="Email Address" name='User_Email'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Email!',
                                        type: 'email',
                                    },

                                ]}>
                                <Input onChange={(e) => setEmail(e.target.value)} />
                            </Form.Item>
                            <Form.Item label="Password" name='Password'
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your Password!',
                                    },
                                ]}>
                                <Input.Password />
                            </Form.Item>
                            <br />
                            <Button type='primary' htmlType='submit' block loading={loading}>Create Account</Button>

                        </Form>





                    </div>
                </div>
            </div>
        </>

    );
}

export default Signup;