import React, { useState } from 'react';
import { Button, Col, Form, Input, Row, message, Card } from 'antd';
import Config from '../../Config';
import axios from 'axios';
import LoginCheck from '../Shared/LoginCheck';

const SendInvitation = () => {




  // messages
  const [messageApi, contextHolder] = message.useMessage();

  const AccessKey = localStorage.getItem("AccessKey");
  const UserID = localStorage.getItem("ID");
  const [loading, setLoading] = useState(false);

  const [form] = Form.useForm();

  const handleSubmit = (formData) => {
    setLoading(true);
    const data = {
      AccessKey: AccessKey,
      UserID: UserID,
      ...formData
    }

    // console.log(data);
    var api_config = {
      method: 'post',
      url: Config.base_url + 'Invitation/SendJoinInvitation',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };


    axios(api_config)
      .then(function (response) {
        // console.log("response", response.data)

        if (response.data.status_code == 1) {
          message.success(response.data.status_message);
          setLoading(false);
          form.resetFields();
        } else if (response.data.status_code == 0) {

          message.error(response.data.status_message);
          setLoading(false);
        }

      })
      .catch(function (error) {
        console.log("response", error)
      });
  }




  return (
    <>
      {contextHolder}
      <LoginCheck />
      <Row justify="center">
        <Col xs={24} md={12}>

          <Card title="Send Join Invitation">
            <Form layout='vertical' size='large' onFinish={handleSubmit} form={form}>
              <Row>
                <Col xs={24}>
                  <Form.Item
                    label="Company Code"
                    name="CompanyCode"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col xs={24}>
                  <Form.Item
                    label="Email"
                    name="Email"
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

              </Row>
              <div style={{ textAlign: "right", marginTop: "20px" }}>
                <Button type='primary' htmlType='submit' loading={loading}> Send Invitation
                </Button>
              </div>
            </Form>
          </Card>
        </Col>
      </Row>

    </>
  )
}

export default SendInvitation