import React from "react";

import Logo from "../../assets/images/logo/dark-h.svg";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Button, Col, Form, Input, Row, Upload, Dropdown } from 'antd';
import { UploadOutlined } from '@ant-design/icons';



function Dashboard(props) {



    const AccessKey = localStorage.getItem("AccessKey");
    const UserID = localStorage.getItem("ID");
    const FullName = localStorage.getItem("Full_Name");

    const navigate = useNavigate();

    const handleSignOut = () => {
        localStorage.clear();
        navigate('/login');
    }


    const items = [
        {
            key: '1',
            label: (
                <a rel="noopener noreferrer" href="#">
                    Edit Profile
                </a>
            ),
        },
        {
            key: '2',
            label: (
                <a rel="noopener noreferrer" href="#" onClick={handleSignOut}>
                    Sign Out
                </a>
            ),
            // icon: <SmileOutlined />,
        },

    ];



    return (
        <>
            <div className="right-side-contents">
                <div className="page-content">
                    <div className="page-header">
                        <h3 className="page-title">Dashboard</h3>


                    </div>
                </div>
            </div>
        </>
    );
}

export default Dashboard;